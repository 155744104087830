import type React from 'react'

import { forwardRef } from 'react'
import styled from '@emotion/styled'

import { Container, ContainerProps, flexContainerSpacingCss, Subcontainer } from './Section'

import { MOBILE_WIDTH_PX } from '../constants'
import { DEFAULT, TITLES } from '../font'

export const CenteredSubcontainer = styled(Subcontainer)`
  text-align: center;
`

export const SpacingContainer = styled.div`
  ${flexContainerSpacingCss}
`

export const Title = styled.h2`
  text-align: center;
  max-width: 964px;
  ${TITLES[1000]}
  margin: 0 auto 8px;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${TITLES[700]}
  }
`

export const Subtitle = styled.p`
  text-align: center;
  ${DEFAULT[500]}
  margin: 0;
  color: ${(props): string => props.theme.outwrite.colors.gray[700]};
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${DEFAULT[200]}
  }
`

export type CenteredSectionProps = ContainerProps

export const CenteredSection: React.FC<CenteredSectionProps> = forwardRef<
HTMLDivElement,
CenteredSectionProps
>(
  ({ children, bgColor, ...rest }, ref) => {
    return (
      <Container bgColor={bgColor} {...rest} ref={ref}>
        <CenteredSubcontainer>
          {children}
        </CenteredSubcontainer>
      </Container>
    )
  }
)
CenteredSection.displayName = 'CenteredSection'
