// Types
import type React from 'react'
import type { ActionTypes } from '../types'
import type { IntersectionOptions } from 'react-intersection-observer'

// External imports
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

// Misc
import { CenteredSection, CenteredSectionProps, Title, Subtitle } from './CenteredSection'
import PrimaryAction from './PrimaryAction'

import { MOBILE_WIDTH_PX } from '../constants'

const ResponsiveSection = styled(CenteredSection)`
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    display: none;
  }
`

const Paragraph = styled(Subtitle)`
  max-width: 660px;
  margin: 0 auto;
`

const ActionWrapper = styled.div`
  overflow: hidden;
  width: fit-content;
  margin: 0 auto;
`

const ActionLink = styled(PrimaryAction)`
  margin-top: 64px;
  align-self: center;
`

type Content = string | JSX.Element

interface OwnProps {
  readonly title: Content
  readonly description?: Content
  readonly action?: ActionTypes
}

type Props = OwnProps & CenteredSectionProps

const IN_VIEW_OPTIONS: IntersectionOptions = { triggerOnce: true, threshold: 0.7, delay: 200 }

const BottomCta: React.FC<Props> = ({ title, description, action, ...rest }) => {
  const { ref: actionRef, inView: actionInView } = useInView(IN_VIEW_OPTIONS)
  return (
    <ResponsiveSection {...rest}>
      <Title>{title}</Title>
      {description && <Paragraph>{description}</Paragraph>}
      {action &&
        <ActionWrapper
          ref={actionRef}
          style={{
            transform: actionInView ? 'translateX(0)' : 'translateX(100px)',
            opacity: actionInView ? 1 : 0,
            transition: 'transform .25s cubic-bezier(0, 0, 0.2, 1), opacity .25s'
          }}
        >
          <ActionLink {...action} clickId='bottom-cta' />
        </ActionWrapper>
      }
    </ResponsiveSection>
  )
}

export default BottomCta
