import { useState, useEffect } from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'

interface Scroll {
  ref: (node?: Element | null | undefined) => void
  scrolledPassed: boolean
}

const useScrollPassed = (
  opts: IntersectionOptions = { threshold: 0, initialInView: false }
): Scroll => {
  const { ref, inView, entry } = useInView(opts)
  const [scrolledPassed, setScrolledPassed] = useState<boolean>(false)
  useEffect(() => {
    setScrolledPassed(!inView && !!entry && entry.boundingClientRect.top < 0)
  }, [inView, entry])

  return { ref, scrolledPassed }
}

export default useScrollPassed
