// Types
import type React from 'react'
import type { ActionTypes } from '../../types'
import type { BaseLocalizedProps } from './types'

// External imports
import { useState } from 'react'
import Image from '../NextImage'
import { useRouter } from 'next/router'

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { TertiaryButton, TertiaryLinkButton } from '@outwrite/button'

// HOCs
import persistQueriesLink from '../../hocs/persist-queries-link'

// Analytics
import analytics from '../../util/analytics'
import withDefault from '../../util/with-default'

// Components
import PrimaryAction from '../PrimaryAction'
import ColoredBackground, { BackgroundProps, themedBackgroundCss } from '../ColoredBackground'
import LogoHomeLink, { Props as LogoHomeLinkProps } from '../LogoHomeLink'

// Assets
import MenuIcon from '../../../public/assets/icons/menu.svg'

// Constant imports
import {
  MOBILE_HEADER_MENU_ZINDEX,
  MOBILE_WIDTH_PX,
  SIGN_IN_LINKS_BY_LOCALE,
  STICKY_HEADER_ZINDEX
} from '../../constants'

const STICKY_ATTR = 'data-sticky'
const HEADER_CTA_BUTTON_ID = 'header-cta'

export interface HeaderContainerProps extends BackgroundProps {
  readonly [STICKY_ATTR]?: boolean
}

const commonStickySubcontainerCss = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: transparent;

  width: 90%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 48px 0;
  position: relative;
`

const smallPaddingCss = css`
  padding-top: 12px;
  padding-bottom: 12px;
  width: 95%;
`

const Subcontainer = styled.div`
  ${commonStickySubcontainerCss}
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${smallPaddingCss}
  }
`

const StickyHeaderContainer = styled.div<HeaderContainerProps>`
  ${themedBackgroundCss}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: ${(props): string => props.theme.boxShadow.secondary};
  z-index: ${STICKY_HEADER_ZINDEX};
  #${HEADER_CTA_BUTTON_ID} {
    display: inline-flex;
    opacity: 1;
    margin-left: 16px;
    @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
      margin-left: 0;
    }
  }

  opacity: 0;
  transform: translateY(-100px);
  pointer-events: none;
  &[${STICKY_ATTR}=true] {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
  transition:
    opacity ${(props): string => props.theme.transitionDuration},
    transform ${(props): string => props.theme.transitionDuration};
`

const StickySubcontainer = styled.div`
  ${commonStickySubcontainerCss}
  ${smallPaddingCss}
`

const MobileMenu = styled(TertiaryButton)`
  display: none;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    display: inline-flex;
  }
`

interface VisibleProps {
  readonly visible: boolean
}

const LinkContainer = styled.div<VisibleProps>`
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    z-index: ${MOBILE_HEADER_MENU_ZINDEX};
    position: absolute;
    top: 120%;
    left: 0;
    visibility: ${(props): string => props.visible ? 'visible' : 'hidden'};


    background-color: ${(props): string => props.theme.outwrite.colors.black};

    display: flex;
    flex-direction: column;
    width: 100%;
    > a {
      color: ${(props): string => props.theme.outwrite.colors.white};
      border-color: transparent;
    }
    padding: 16px 0;
    border-radius: ${(props): number => props.theme.outwrite.borderRadii.medium}px;

    transform: scaleY(${(props): string => props.visible ? '100%' : '0'});
    transform-origin: top;
    transition: transform ${(props): string => props.theme.transitionDuration};
    box-shadow: ${(props): string => props.theme.outwrite.shadows.outer[500]};
  }
`

const PersistQueryTertiaryLink = persistQueriesLink(TertiaryLinkButton)

type Props = (
  LogoHomeLinkProps &
  HeaderContainerProps &
  { action: ActionTypes } &
  BaseLocalizedProps
)

const Header: React.FC<Props> = ({ t, tOptions, dark, bgColor, action, imgProps, ...rest }) => {
  const router = useRouter()

  const [menuOpened, setMenuOpened] = useState(false)

  // TODO: add aria-expand & aria popup
  const mobileMenuButton = (
    <MobileMenu
      onClick={(): void => setMenuOpened(state => !state)}
      size='xsmall'
      aria-label={t('header.MENU_BUTTON_ARIA_LABEL')}
    >
      <Image src={MenuIcon} alt='' />
    </MobileMenu>
  )

  const signinButton = (
    <PersistQueryTertiaryLink
      {...withDefault(SIGN_IN_LINKS_BY_LOCALE, router.locale)}
      onClick={(): void => analytics.trackClick('sign-in', router.pathname)}
    >
      {t('header.LOGIN', tOptions)}
    </PersistQueryTertiaryLink>
  )

  return (
    <header>
      <ColoredBackground bgColor={bgColor}>
        <Subcontainer {...rest}>
          <LogoHomeLink imgProps={{ priority: true, ...imgProps }} dark={dark} />
          {mobileMenuButton}
          <LinkContainer visible={menuOpened}>
            {signinButton}
          </LinkContainer>
        </Subcontainer>
      </ColoredBackground>
      <StickyHeaderContainer
        {...(rest[STICKY_ATTR] ? {} : { 'aria-hidden': 'true' })}
        {...{ [STICKY_ATTR]: rest[STICKY_ATTR] }}
        data-bgcolor={bgColor}
      >
        <StickySubcontainer>
          <LogoHomeLink imgProps={{ priority: true, ...imgProps }} dark={dark} />
          {mobileMenuButton}
          <LinkContainer visible={menuOpened}>
            {signinButton}
            <PrimaryAction
              id={HEADER_CTA_BUTTON_ID}
              clickId='header-cta-get-started'
              useShort
              {...action}
              size='medium'
              responsive={[]}
            />
          </LinkContainer>
        </StickySubcontainer>
      </StickyHeaderContainer>
    </header>
  )
}

export default Header
