import type { OutwriteShadesBrandColor, ThemeProps } from '@outwrite/themes'

import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'

// Can use either bgColor or data attribute (to prevent react from complaining...)
// TODO:? find a better way?
export type BackgroundProps = Partial<Record<'bgColor' | 'data-bgcolor', keyof OutwriteShadesBrandColor | 'black'>>

export const themedBackgroundCss = (props: BackgroundProps & ThemeProps): SerializedStyles => {
  const bgColor = props.bgColor ?? props['data-bgcolor']
  return css`
    ${bgColor === 'black' ? `color: ${props.theme.outwrite.colors.white};` : ''}
    background-color: ${bgColor === 'black'
      ? props.theme.outwrite.colors.black
      : bgColor === 'yellow'
        ? props.theme.outwrite.colors.yellow[500]
        : bgColor
          ? props.theme.outwrite.colors[bgColor][300]
          : props.theme.background};
  `
}

const Background = styled.div<BackgroundProps>`
  ${themedBackgroundCss}
`

export default Background
