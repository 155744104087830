// Type imports
import type React from 'react'
import type { OutwriteShadesBrandColor, ThemeProps } from '@outwrite/themes'
import type { SerializedStyles } from '@emotion/react'

// External imports
import styled from '@emotion/styled'
import { css } from '@emotion/react'

// Constants
import { MOBILE_WIDTH_PX, TABLET_WIDTH_PX } from '../constants'
import { DEFAULT, TITLES } from '../font'

const MAX_WIDTH_PX = 1157

export interface ContainerProps {
  readonly bgColor?: keyof OutwriteShadesBrandColor | 'black'
}

export const Container = styled.section<ContainerProps>`
  ${(props): string => props.bgColor === 'black' ? `color: ${props.theme.outwrite.colors.white};` : ''}
  background-color: ${(props): string => props.bgColor === 'black'
    ? props.theme.outwrite.colors.black
    : props.bgColor
      ? props.theme.outwrite.colors[props.bgColor]['100']
      : props.theme.background};
  padding: 128px 64px;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    padding: 96px 64px;
  }
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    padding: 64px 32px;
  }
`

export const Subcontainer = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH_PX}px;
  margin: 0 auto;
`

type SectionProps = ContainerProps

export const Section: React.FC<SectionProps> = ({ children, bgColor, ...rest }) => {
  return (
    <Container bgColor={bgColor} {...rest}>
      <Subcontainer>
        {children}
      </Subcontainer>
    </Container>
  )
}

interface FlexProps {
  readonly direction?: 'row' | 'row-reverse'
}

const FLEX_CONTAINER_MARGIN_BOTTOM = 96
const FLEX_CONTAINER_MARGIN_BOTTOM_MOBILE = 40
const FLEX_ITEM_MARGIN = 32
const FLEX_ITEM_MARGIN_TABLET = 16
const FLEX_ITEM_MARGIN_MOBILE = 8

export const flexContainerSpacingCss = css`
  margin-bottom: ${FLEX_CONTAINER_MARGIN_BOTTOM}px;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    margin-bottom:  ${FLEX_CONTAINER_MARGIN_BOTTOM_MOBILE}px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`

// Note: the negative margins used as an alternative to the "gap" css property
export const FlexContainer = styled.div<FlexProps>`
  ${flexContainerSpacingCss}

  display: flex;
  flex-direction: ${(props): string => props.direction ?? 'row'};
  justify-content: center;
  align-items: center;

  margin-top: -${FLEX_ITEM_MARGIN}px;
  margin-left: -${FLEX_ITEM_MARGIN}px;
  margin-right: -${FLEX_ITEM_MARGIN}px;
  margin-bottom: ${FLEX_CONTAINER_MARGIN_BOTTOM - FLEX_ITEM_MARGIN}px;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    margin-top: -${FLEX_ITEM_MARGIN_TABLET}px;
    margin-left: -${FLEX_ITEM_MARGIN_TABLET}px;
    margin-right: -${FLEX_ITEM_MARGIN_TABLET}px;
    margin-bottom: ${FLEX_CONTAINER_MARGIN_BOTTOM - FLEX_ITEM_MARGIN_TABLET}px;
  }
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    flex-direction: column;
    margin-top: -${FLEX_ITEM_MARGIN_MOBILE}px;
    margin-left: -${FLEX_ITEM_MARGIN_MOBILE}px;
    margin-right: -${FLEX_ITEM_MARGIN_MOBILE}px;
    margin-bottom: ${FLEX_CONTAINER_MARGIN_BOTTOM_MOBILE - FLEX_ITEM_MARGIN_MOBILE}px;
  }
`

export const Item = styled.div`
  flex: 1 1 0%;

  margin: ${FLEX_ITEM_MARGIN}px;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    margin: ${FLEX_ITEM_MARGIN_TABLET}px;
  }
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    margin: ${FLEX_ITEM_MARGIN_MOBILE}px;
  }
`

export const FlexItem = styled(Item)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    align-items: center;
    text-align: center;
  }
`

export const Title = styled.h2`
  ${TITLES[1000]}
  margin: 0 0 16px 0;

  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    font-size: 41px;
    line-height: 42px;
    margin-bottom: 12px;
  }
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${TITLES[700]}
    margin-bottom: 8px;
  }
`

export const descriptionCss = (props: ThemeProps): SerializedStyles => css`
  ${DEFAULT[500]}
  color: ${props.theme.outwrite.colors.gray[700]};
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    ${DEFAULT[200]}
  }
`

export const Description = styled.p`
  ${descriptionCss}
  margin: 0 0 32px 0;
  @media screen and (max-width: ${TABLET_WIDTH_PX}px) {
    margin-bottom: 16px;
  }
`
