// Type imports
import type React from 'react'
import type { Props as ButtonProps } from '@outwrite/button'
import type { BaseAction } from '../types'

// External imports
import { useRouter } from 'next/router'
import { forwardRef } from 'react'
import { PrimaryLinkButton } from '@outwrite/button'

// Utils
import analytics, { ClickId } from '../util/analytics'

// HOCs
import persistQueriesLink from '../hocs/persist-queries-link'

import { MOBILE_WIDTH_PX } from '../constants'

export type Props = (
  ButtonProps &
  BaseAction &
  React.HTMLAttributes<HTMLButtonElement> &
  React.HTMLAttributes<HTMLAnchorElement> &
  { clickId?: ClickId }
)

const PrimaryAction = forwardRef<HTMLButtonElement & HTMLAnchorElement, Props>(({
  text,
  shortText,
  useShort,
  clickId,
  ...rest
}, ref) => {
  const router = useRouter()
  return (
    <PrimaryLinkButton
      {...rest}
      ref={ref}
      size={rest.size ?? 'xlarge'}
      responsive={rest.responsive ?? [{ mediaQuery: `screen and (max-width: ${MOBILE_WIDTH_PX}px)`, size: 'large' }]}
      onClick={clickId
        ? (e): void => {
            analytics.trackClick(clickId, router.pathname)
            // @ts-expect-error TODO: FIx typing ...
            if (rest.onClick) rest.onClick(e)
          }
        : rest.onClick}
    >
      {(useShort ? shortText : text) ?? text}
    </PrimaryLinkButton>
  )
})
PrimaryAction.displayName = 'PrimaryAction'

export default persistQueriesLink(PrimaryAction)
